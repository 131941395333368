/* eslint-disable react/prop-types */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "../CustomButtons/Button.js";

import ReslicoApi from "../../services/network/api";
const reslicoApi = new ReslicoApi();

function createData(name, forsale, rent) {
  return { name, forsale, rent };
}

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
  [`&`]: {
    backgroundColor: "transparent",
    color: theme.palette.common.white,
    padding: "2px 2px 5px 5px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&`]: {
    backgroundColor: "transparent",
    color: theme.palette.common.white,
    borderColor: "transparent",
    padding: "2px 2px 5px 5px",
  },
}));

export default function BasicTable(props) {
  const { data } = props;

  if (data === undefined) {
    return <></>;
  }

  const rows = Object.keys(data)
    .slice(0, 10)
    .map((x) => {
      return createData(x, data[x].forsale, data[x].rent);
    });
  return (
    <TableContainer
      component={Paper}
      sx={{ backgroundColor: "black", padding: "0px" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "transparent",
              color: "white",
              padding: "0px",
            }}
          >
            <StyledTableHeader>Country</StyledTableHeader>
            <StyledTableHeader align="center">
              For Sale Listings
            </StyledTableHeader>
            <StyledTableHeader align="center">
              For Rent Listings
            </StyledTableHeader>
            <StyledTableHeader align="center">
              Download Sample
            </StyledTableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                padding: "0px",
              }}
            >
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.forsale}</StyledTableCell>
              <StyledTableCell align="center">{row.rent}</StyledTableCell>
              <StyledTableCell align="center">
                <Button
                  round
                  color="gray"
                  size="sm"
                  rel="noopener noreferrer"
                  onClick={() => {
                    reslicoApi.getCountrySampleData(row.name);
                  }}
                >
                  <i className="fas fa-download" />
                  Download Sample
                </Button>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
