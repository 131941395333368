import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Countries from "components/Countries/Countries";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Reslico"
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "black",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/background.jpg").default}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>
                Real Estate Data From All Over The World
              </h1>
              <h3>
                We provide clean, structured data for real estate listings all
                over the world. For sale & rent listings gathered from publicly
                available different sources, cleaned up and ready to for you to
                analyse.
              </h3>
              <br />
              <h3>
                Check the table below for free samples. All numbers are from
                past 30 days.
              </h3>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div
        className={classNames(classes.main, classes.mainRaised)}
        color="black"
      >
        <Countries />

        {/* <div className={classes.container}>
          <ProductSection />
          <TeamSection />
          <WorkSection />
        </div> */}
      </div>
      <Footer />
    </div>
  );
}
