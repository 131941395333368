import React, { useState, useEffect } from "react";
import ReslicoApi from "../../services/network/api";
import CountriesTable from "./CountriesTable";

const reslicoApi = new ReslicoApi();

const Countries = (props) => {
  const [countriesData, setCountriesData] = useState(null);

  useEffect(() => {
    if (countriesData == null) {
      reslicoApi
        .getCountriesAmounts()
        .then((x) => setCountriesData(x))
        .catch((x) => {
          setCountriesData("error");
          console.log("error: " + x);
        });
    }
  }, [countriesData]);

  return (
    <>
      {countriesData === null && <div>Loading...</div>}
      {countriesData && countriesData === "error" && (
        <div>Error loading countries table...</div>
      )}
      {countriesData && countriesData !== "error" && (
        <div>
          <CountriesTable data={countriesData} />
        </div>
      )}
    </>
    //   className={countriesData ? styles.checkboxChecked : styles.checkbox}
    //   onClick={change}
  );
};

export default Countries;
