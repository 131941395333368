import axios from "axios";
import { API_URL } from "./config";

export default class ReslicoApi {
  requestGET(path) {
    const url = `${API_URL}/${path}`;
    return axios
      .get(url)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getCountriesAmounts() {
    return this.requestGET("countries")
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getCountrySampleData(country) {
    return this.requestGET(`sample?country=${country}`)
      .then((response) => {
        const filename = response.headers["filename"] || "reslico_sample.csv";

        const encodedUri = encodeURIComponent(response.data);
        const link = document.createElement("a");
        link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link);

        link.click();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
